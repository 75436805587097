import React from "react";
import "./reset.scss";
import ReactDOM from "react-dom/client";
import { Router } from "./router";
import { BrowserRouter } from "react-router-dom";
import AnimatedCursor from "react-animated-cursor";
import ScrollToTop from "./Common/Context/ScrollToTop";
import ThemeProvider from "./Common/Context/ThemeContext";
import { Analytics } from '@vercel/analytics/react';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider>
    <React.StrictMode>
      <BrowserRouter>
        <Analytics />
        <ScrollToTop />
        <AnimatedCursor
          innerSize={8}
          outerSize={30}
          innerScale={0.7}
          outerScale={2}
          outerAlpha={0}
          outerStyle={{
            border: "2px solid var(--red-color)",
            zIndex: 99999,
          }}
          innerStyle={{
            backgroundColor: "var(--subtitle-color)",
            zIndex: 99999,
          }}
          clickables={[
            "a",
            'input[type="text"]',
            'input[type="email"]',
            'input[type="tel"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            "label[for]",
            "select",
            "textarea",
            "button",
            ".link",
          ]}
        />
        <Router />
      </BrowserRouter>
    </React.StrictMode>
  </ThemeProvider>,
);
