import React, { createContext, useState, useEffect } from "react";
export const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("dev");

  useEffect(() => {
    if (
      localStorage.getItem("theme") === "hacker" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: hacker)").matches)
    ) {
      document.querySelector("html").classList.add("hacker");
      setTheme("hacker");
    } else if (localStorage.getItem("theme") === "cyber") {
      document.querySelector("html").classList.add("cyber");
      setTheme("cyber");
    } else if (localStorage.getItem("theme") === "white") {
      document.querySelector("html").classList.add("white");
      setTheme("white");
    } else {
      document
        .querySelector("html")
        .classList.remove("hacker", "cyber", "white");
      setTheme("dev");
    }
  }, []);

  function toggleThemeMode() {
    if (localStorage.getItem("theme") === "dev") {
      localStorage.setItem("theme", "hacker");
      document.querySelector("html").classList.add("hacker");
      document.querySelector("html").classList.remove("cyber", "white");
      setTheme("hacker");
    } else if (localStorage.getItem("theme") === "hacker") {
      localStorage.setItem("theme", "cyber");
      document.querySelector("html").classList.add("cyber");
      document.querySelector("html").classList.remove("hacker", "white");
      setTheme("cyber");
    } else if (localStorage.getItem("theme") === "cyber") {
      localStorage.setItem("theme", "white");
      document.querySelector("html").classList.add("white");
      document.querySelector("html").classList.remove("hacker", "cyber");
      setTheme("white");
    } else {
      localStorage.setItem("theme", "dev");
      document
        .querySelector("html")
        .classList.remove("hacker", "cyber", "white");
      setTheme("dev");
    }
  }

  return (
    <ThemeContext.Provider value={{ theme, toggleThemeMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
