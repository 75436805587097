import "./Style/Loading.modules.scss";
import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";

export default function Loading() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      {loading ? (
        <section className="loadingContainer">
          <Oval
            color="var(--loading-color)"
            radius="0"
            secondaryColor="var(--loading-color)"
          />
        </section>
      ) : (
        <></>
      )}
    </>
  );
}
